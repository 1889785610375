import Vue from "vue";
import Vuex, { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth0 from "./modules/auth0";
import profile from "./modules/profile";
import questionnaires from "./modules/questionnaires";
import questions from "./modules/questions";
import notes from "./modules/notes";
import matches from "./modules/matches";
import scoreMatches from "./modules/scoreMatches";
import categories from "./modules/categories"
import issues from "./modules/issues"
import stat from "./modules/stat"
import linkGroups from "@/store/modules/linkGroups";

Vue.use(Vuex);

const store = new Store({
  modules: {
    auth0,
    categories,
    profile,
    issues,
    questionnaires,
    questions,
    notes,
    stat,
    matches,
    linkGroups,
    scoreMatches
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: "ArielLocalStorage",
      paths: ["auth0"]
    })
  ]
});

export default store;
