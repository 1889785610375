import Vue from "vue"
import Router from "vue-router"

import store from "../store"

// Containers
const TheContainer = () => import("@/containers/TheContainer")

// Views - Pages
const Page404 = () => import("@/views/pages/Page404")
const Page500 = () => import("@/views/pages/Page500")

// Questionnaires
const Questionnaires = () => import("@/views/questionnaires")
const Questionnaire = () => import("@/views/questionnaires/_id")

// Questions
const QuestionsView = () => import("@/views/questions")
const QuestionView = () => import("@/views/questions/_id")

// Matches
const Matches = () => import("@/views/matches")
const EditMatch = () => import("@/views/matches/editMatch")

// SummariesView
const SummariesView = () => import('@/views/summaries')
const SummaryIDView = () => import('@/views/summaries/_id')

// CategoriesView

const CategoriesView = () => import("@/views/categories")



// Auth0
const Auth0Callback = () => import("@/views/auth0/callback")

Vue.use(Router)

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

router.beforeEach((to, from, next) => {
  const isAuth = store.getters["auth0/isAuthenticated"]
  const isTokenExp = store.getters["auth0/isTokenExpired"]
  const isAuthCB = to.name === "Auth0Callback"
  if (!isAuthCB && !isAuth) return store.dispatch("auth0/login")
  if (isAuth && isTokenExp) return store.dispatch("auth0/logout")
  next()
})

export default router

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/questionnaires",
      component: TheContainer,
      children: [
        {
          path: "questionnaires",
          meta: { label: "Анкеты" },
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Questionnaires",
              component: Questionnaires,
            },
            {
              path: ":id",
              name: "Questionnaire",
              meta: { label: "Анкета" },
              component: Questionnaire,
            },
          ],
        },
        {
          path: "stats",
          component: {
          render(c) {
              return c("router-view")
            }
          },
          meta: { label: "Статистика" },
          children: [
            {
              path: "answers",
              name: "StatsAnswer",
              component: () => import("@/views/stats/answers.vue"),
              meta: { label: "Статистика по ответам" },
            },
            {
              path: "bot",
              name: "BotStats",
              component: () => import("@/views/stats/bot.vue"),
              meta: { label: "Статистика телеграм боту" },
            },
            {
              path: "types",
              name: "StatsTypes",
              component: () => import("@/views/stats/types.vue"),
              meta: { label: "Статистика по типам" },
            },
            {
              path: "activity",
              name: "StatsActivity",
              component: () => import("@/views/stats/activity.vue"),
              meta: { label: "Статистика по активностям" },
            },
            {
              path: "unique",
              name: "StatsUnique",
              component: () => import("@/views/stats/unique.vue"),
              meta: { label: "Статистика по уникам" },
            },
            {
              path: "promocodes",
              name: "StatsPromo",
              component: () => import("@/views/stats/promocodes.vue"),
              meta: { label: "Статистика по промокодам" },
            }
          ]
        },
        {
          path: "group-links",
          meta: { label: "Группы связок" },
          component: {
            render(c) {
              return c("router-view")
            }
          },
          children: [
            {
              path: "",
              name: "GroupLink",
              component: () => import("@/views/group-links/index")
            }
          ]
        },
        {
          path: "questions",
          meta: { label: "Вопросы" },
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Questions",
              component: QuestionsView,
            },
            {
              path: ":id",
              name: "QuestionsId",
              meta: { label: "Вопрос" },
              component: QuestionView,
            },
          ],
        },
        {
          path: "summaries",
          meta: { label: "Анализ по баллам" },
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Summaries",
              component: SummariesView,
            },
            {
              path: ':id',
              name: 'Summary',
              meta: { label: 'Связка по баллам'},
              component: SummaryIDView,
            }
          ]
        },
        {
          path: "issues",
          name: "Issues",
          meta: { label: "Проблемные комментарии" },
          component: () => import("@/views/issues")
        },
        {
          path: "categories",
          meta: { label: "Категории" },
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Categories",
              component: CategoriesView,
            },
          ],
        },
        {
          path: "matches",
          meta: { label: "Связки" },
          component: {
            render(c) {
              return c("router-view")
            },
          },
          children: [
            {
              path: "",
              name: "Matches",
              component: Matches,
            },
            {
              path: "edit-match",
              name: "EditMatch",
              meta: { label: "Редактирование связки" },
              component: EditMatch,
            }
          ],
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view")
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },
    {
      path: "/auth0",
      name: "Auth0",
      component: TheContainer,
      children: [
        {
          path: "callback",
          name: "Auth0Callback",
          component: Auth0Callback,
        },
      ],
    },
  ]
}
