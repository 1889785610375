import API from "@/services/api";
import { formatTimestamp } from "@/utils";

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
    activityStat: [],
    uniqueStats: {
      total: 0,
      countOpened: 0,
      countUnopened: 0,
      unOpenedTests: []
    },
    botActivityStats: [],
    botActivityStatsMapped: [],
    typesStats: {
      ageStats: [],
      genderStats: [],
      childrenStats: [],
      familyStats: [],
      businessStats: [],
      promoCodeStats: [],
      total: 0
    },
  },

  mutations: {
    SET_ANSWER_STATS(state, { list, total }) {
      state.list = list;
      state.total = total;
    },
    SET_TYPES_STATS(state, data) {
      state.typesStats = { ...data }
    },
    SET_ACTIVITY_STATS(state, data) {
      state.activityStat = data;
    },
    SET_UNIQUE_STATS(state, data) {
      state.uniqueStats = data
    },
    SET_BOT_ACTIVITY_STATS(state, data) {
      state.botActivityStats = data.list;
    },
    SET_BOT_ACTIVITY_STATS_MAPPED(state, data) {
      state.botActivityStatsMapped = data.list.map(item => ({
        ...item,
        botStart: formatTimestamp(item.botStart),
        botTestStart: formatTimestamp(item.botTestStart),
        botTestFinish: formatTimestamp(item.botTestFinish),
      }));
    }
  },
  actions: {
    getAnswersStats({ commit }, params = {}) {
      return API.get("AnswerStats", { params }).then((data) =>
        commit("SET_ANSWER_STATS", {
          list: data.list,
          total: data.total,
        })
      );
    },
    getUniqueStats({ commit }, params = {}) {
      return API.get("TestStats/UnOpened", { params }).then((data) =>
        commit("SET_UNIQUE_STATS", data)
      );
    },
    getTypesStats({ commit }, params = {}) {
      return API.get("TestStats", { params }).then((data) =>
        commit("SET_TYPES_STATS", data)
      );
    },
    getActivityStats({ commit }, params = {}) {
      return API.get("ActivityStats", { params }).then((data) =>
        commit("SET_ACTIVITY_STATS", data)
      );
    },
    getBotActivityStats({ commit }, params = {}) {
      return API.get("BotActivityStats", { params }).then((data) => {
        commit("SET_BOT_ACTIVITY_STATS", data);
        commit("SET_BOT_ACTIVITY_STATS_MAPPED", data);
      }
      );
    },
  },

  getters: {},
};
