import { format, fromUnixTime } from "date-fns"
import { ru } from "date-fns/locale"

/**
 * @param {Date} date 
 */
export const formatDate = (date) => {
    return format(date, "dd/MM/yyyy HH:mm:ss", {
        locale: ru
    })
}

/**
 * @param {number} timestamp
 */
export const formatTimestamp = (timestamp) => {
    return format(fromUnixTime(timestamp), "dd/MM/yyyy HH:mm:ss", {
        locale: ru
    })
}

