// import accounting from 'accounting'
import moment from 'moment-timezone'

export default {
  methods: {
    $generateQURL(qId) {
      return `${process.env.VUE_APP_PA_DOMAIN}/test?id=${qId}`;
    },
    $removeAllNonNumericCharachers (stringToClear) {
      return Number(stringToClear.replace(/\D/g,''));
    },
    $updateForm(formName, formKey, value) {
      if (typeof value === 'object') {
          this[formName][formKey] = 0;
      } else {
          this[formName][formKey] = value;
      }
    },
    $QStatus (status) {
      let str = 'Загрузка...'
      switch (status) {
        case 0: str = 'Новая'; break
        case 1: str = 'Ждет оператора '; break
        case 2: str = 'Ждет куратора'; break
        case 3: str = 'Законченная'; break
        case 4: str = 'В работе'; break
      }
      return str
    },
    $soon () {
      alert('Скоро.')
    },
    $slctVal (v) {
      return v.value === undefined ? v : v.value
    },
    $objectLen (obj) {
      if (!obj) return
      return Object.keys(obj).length
    },
    $clearPhone (phone) {
      if (!phone) return ''
      phone = phone.slice(3, phone.length)
      return phone.replace(/\D/g, '')
    },
    $moment (time) {
      return moment.tz(time, moment.tz.guess()).locale('ru')
    },
    // $price (v) {
    //   return accounting.formatNumber(v, 0, ' ')
    // },
    $date (time, format) {
      return moment(time).tz(moment.tz.guess()).locale('ru').format(format)
    },
    // $text (value, arg) {
    //   const store = this.$store
    //   if (store) return this.$t(value, store.state.i18n.lang, arg)
    // }
  }
}
