import API from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    totalQuestions: 0,

    listMapId: {},
    question: {}
  },

  mutations: {
    SET_QUESTIONS_LIST (state, {list, total}) {
      state.list = list
      state.totalQuestions = total

      let listMapId = {}
      list.forEach(item => listMapId[item.blockId] = item)
      state.listMapId = listMapId
    },
    SET_QUESTION (state, {question}) {
      state.question = question
    }
  },
  actions: {
    getList ({ commit }, params = {from: 0, to: 999999999}) {
      return API.get('Questions', {params}).then(data => commit('SET_QUESTIONS_LIST', {
        list: data.list, total: data.total
      }))
    },
    getQuestion ({ commit }, params = {id: 0}) {
      return API.get('Questions', {params}).then(data => commit('SET_QUESTION', {
        question: data.list[0]
      }))
    }
  },

  getters: {
    getQuestionsfromId: state => (id) => state.listMapId[id] || {}
  }
}
