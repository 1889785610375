import API from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
  },

  mutations: {
    SET_CATEGORIES_LIST (state, {list, total}) {
      state.list = list
      state.total = total
    },
  },
  actions: {
    getCategoriesList ({ commit }, params = {}) {
      return API.get('ScoreCategories', {params}).then(data => commit('SET_CATEGORIES_LIST', {
        list: data.list, total: data.total
      }))
    },
    createCategory ({ commit, dispatch, state }, body = {}) {
      return API.post('ScoreCategories', {data: body})
    },
    updateCategory ({ commit, dispatch, state }, body = {}) {
      return API.put('ScoreCategories', {data: body})
    },
    deleteCategory ({}, id = "") {
      return API.delete(`ScoreCategories?id=${id}`, {})
    }
  },

  getters: {
  }
}