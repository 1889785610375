import API from '../../services/api'
import auth0 from '../../services/auth0'

export default {
  namespaced: true,
  state: {
    error: null,
    profile: {
      roles: []
    },
  },

  mutations: {
    GET_PROFILE_FAILURE: (state, { error }) => {
      state.error = error
    },
    GET_PROFILE_SUCCESS: (state, { profile }) => {
      const userRolesKey = Object.keys(profile).find(item => item.includes('user_roles'))
      //profile.roles = profile[userRolesKey]
      profile.roles = ['Operator']
      // delete profile[userRolesKey]
      state.profile = profile
      state.error = null
    }
  },
  actions: {
    get ({ commit, dispatch, state, rootState }) {
      return new Promise((resolve, reject) => {
        auth0.client.userInfo(rootState.auth0.accessToken, (err, profile) => {
          if (err) {
            console.error(err)
            if (err.statusCode === 401) return dispatch('auth0/logout', {}, {root: true})
            commit('GET_PROFILE_FAILURE', { error: err })
            return reject(err)
          }
          commit('GET_PROFILE_SUCCESS', { profile })
          return resolve(profile.sub)
        })
      })
    },
    setRoles ({ commit }, roles) {
      commit('SET_USER_ROLES', roles)
    }
  },

  getters: {
    isOperator: state => state.profile.roles.some(i => i == 'Operator'),
    isReviewer: state => state.profile.roles.some(i => i == 'Reviewer') || !state.profile.roles 
  }
}