import axios from "axios";
import { ValidationError } from "@/models/Errors.js";
import store from "../store";
// import vue from './main'

class BaseApiService {
  client = axios.create({
    json: true,
    baseURL: `${process.env.VUE_APP_ENDPOINTPATH}/api/`,
  });

  execute(method, url, { data, params }) {
    const token = store.state.auth0.accessToken;
    const tokenType = store.state.auth0.tokenType;
    let headers = {
      Authorization: `${tokenType} ${token}`,
      "Content-Type": "application/json",
    };
    return this.client({
      method,
      url,
      params,
      data,
      headers,
      // withCredentials: true
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          if(Number(error.response.status) === 401) {
            store.dispatch('auth0/logout');
          }
          if(Number(error.response.status) === 401) {
            store.dispatch('auth0/logout');
          }

          let validationErrors = error.response.data.ValidationErrors;

          if(validationErrors && validationErrors.length > 0) {
            // console.error("ValidationErrors")
            throw new ValidationError(validationErrors);
          }
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
        throw error;
      });
  }

  get(url, params = {}) {
    return this.execute("get", url, params);
  }

  post(url, { data, params }) {
    return this.execute("post", url, { data: data, params });
  }

  put(url, { data, params }) {
    return this.execute("put", url, { data, params });
  }

  delete(url, params) {
    return this.execute("delete", url, params);
  }

  upload() {}
}

export default new BaseApiService();
