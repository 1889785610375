import API from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    totalQuestys: 0,

    questionnaire: {
      answers: [],
      answersMap: {}
    },

    searchForm: {
      status: 5,
      name: '',
      email: '',
      phone: '',
    },
    pagiPage: 1
  },

  mutations: {
    SET_NOTE (state, {note, blockId, index}) {
      state.questionnaire.answersMap[blockId].answer.notes[index] = note;
    },
    REMOVE_NOTE (state, {blockId, index}) {
      state.questionnaire.answersMap[blockId].answer.notes.splice(index, 1);
    },
    ADD_NOTE_TO_ANSWER (state, {note, blockId}) {
      state.questionnaire.answersMap[blockId].answer.notes.push(note);
    },
    SET_QUESTIONNAIRES_LIST (state, {list, total}) {
      state.list = list
      state.totalQuestys = total
    },
    SET_QUESTIONNAIRE (state, { questionnaire }) {
      questionnaire.answers = state.questionnaire.answers
      if('answersMap' in state.questionnaire) questionnaire.answersMap = state.questionnaire.answersMap;
      state.questionnaire = questionnaire
    },
    SET_ANSWERS (state, { answers }) {
      let answersMap = {}
      answers.forEach(el => { answersMap[el.answer.blockId] = el })
      state.questionnaire.answersMap = answersMap;
      state.questionnaire.answers = answers;
    },
    SET_SEARCH_FORM (state, form) {
      state.searchForm = form
    },
    SET_PAGI_PAGE (state, page) {
      state.pagiPage = page
    }
  },
  actions: {
    updateAnswer ({}, body = {}) {
      return API.put('Answers', { data: body })
    },
    getList ({ commit }, params = {from: 0, to: 10, name: '', status: '', email: ''}) {
      return API.get('appforms', {params}).then(data => commit('SET_QUESTIONNAIRES_LIST', {
        list: data.list, total: data.total
      }))
    },
    getQuestionnaire ({ commit }, params = {testId: 0}) {
      return API.get('appforms', {params}).then(data => commit('SET_QUESTIONNAIRE', {
        questionnaire: data.list[0]
      }))
    },
    getAnswers ({ commit }, params = {from: 0, to: 999999999}) {
      return API.get('answers', {params}).then(data => commit('SET_ANSWERS', {
        answers: data
      }))
    },
    updateQuestionnaire ({ commit, dispatch, state }, body = {}) {
      return API.put('appforms', {data: body})
    },
    deleteQuestionnaire ({ commit, dispatch, state }, body = {}) {
      return API.delete('appforms', {data: body})
    }
  },

  getters: {
    getAnswerByBlockID(state) {
      return blockId => {
        return state.questionnaire.answersMap[blockId]?.answer || false
      }
    }
  }
}
