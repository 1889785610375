import API from "@/services/api";

export default {
  namespaced: true,
  state: {
    list: [],
    listMapId: {},
    totalNotes: 0,
    linkedItemsList: [],
    note: {},
  },

  mutations: {
    SET_NOTES_LIST(state, { list, total }) {
      state.list = list;
      state.totalNotes = total;

      let listMapId = {};
      list.forEach((item) => (listMapId[item.id] = item));
      state.listMapId = listMapId;
    },
    SET_NOTE(state, { note }) {
      state.note = note;
    },
    CLEAR_NOTES_LIST(state) {
      state.list = [];
      state.totalNotes = 0;
    },
    SET_LINKED_ITEMS(state, fetchedData) {
      state.linkedItemsList = fetchedData.list;
    },
  },

  actions: {
    getList({ commit }, params = {}) {
      return API.get("Notes", { params }).then((data) =>
        commit("SET_NOTES_LIST", {
          list: data.list,
          total: data.total,
        })
      );
    },

    getNote({ commit }, params = { id: 0 }) {
      return API.get("Notes", { params }).then((data) =>
        commit("SET_NOTE", {
          note: data.list[0],
        })
      );
    },
    createNote({ dispatch }, payload = {}) {
      return API.post("Notes", { data: payload });
    },
    updateNote({ dispatch, state }, payload = {}) {
      return API.put("Notes", { data: payload });
    },
    clearList({ commit }, params = {}) {
      return Promise.resolve().then(() => commit("CLEAR_NOTES_LIST"));
    },
    getNoteScoresItemsList({ commit }, params = {}) {
      return API.get("NoteScoreItems", { params }).then((data) =>
        commit("SET_LINKED_ITEMS", data)
      );
    },
    createNoteScoreItem({ commit }, payload = {}) {
      return API.post("NoteScoreItems", { data: payload });
    },
    deleteNoteScoreItem({}, id = "") {
      return API.delete(`NoteScoreItems?id=${id}`, {});
    },
  },

  getters: {
    getNotefromId: (state) => (id) => state.listMapId[id] || {},
  },
};
