import API from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
  },

  mutations: {
    SET_ISSUES_LIST (state, {list, total}) {
      state.list = list
      state.total = total
    },
  },
  actions: {
    getIssuesList ({ commit }, params = {}) {
      return API.get('Issues', {params}).then(data => commit('SET_ISSUES_LIST', {
        list: data.list, total: data.total
      }))
    },
    createIssue ({ commit, dispatch, state }, body = {}) {
      return API.post('Issues', {data: body})
    },
    updateIssue ({ commit, dispatch, state }, body = {}) {
      return API.put('Issues', {data: body})
    },
    deleteIssue ({}, id = "") {
      return API.delete(`Issues?id=${id}`, {})
    }
  }
}