<template>
  <router-view></router-view>
</template>

<script>
  import { mapActions } from 'vuex';
  
export default {
  name: 'App',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/style';
@import "vue-select/src/scss/vue-select.scss";
</style>
