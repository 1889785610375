import {WebAuth as Auth0} from 'auth0-js'

const auth0 = new Auth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  responseType: 'token id_token',
  // scope: 'profile read:current_user read:users read:roles'
  scope: 'openid profile email',
  audience: `https://${process.env.VUE_APP_AUTH0_DOMAIN}/api/v2/`

})

export default auth0

