import API from '@/services/api';

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
    categoriesList: [],
    categoriesMap: {},
    linkedItemsList: []
  },

  mutations: {
    SET_LIST(state, fetchedData) {
      state.list = fetchedData.list,
      state.total = fetchedData.total
    },
    SET_LINKED_ITEMS (state, fetchedData) {
      state.linkedItemsList = fetchedData.list
    }
  },
  actions: {
    createMatch({}, payload = {}) {
      return API.post('LogicLinks', { data: payload })
    },
    getList({commit}, params = {}) {
      return API.get('LogicLinks', { params }).then(data => commit('SET_LIST', data))
    },
    updateMatch({}, payload = {}) {
      return API.put('LogicLinks', { data: payload })
    },
    deleteMatch({}, id = '') {
      return API.delete(`LogicLinks?id=${id}`, {});
    },
    getLogicLinksItemsList({commit}, params = {}) {
      return API.get('LogicLinkItems', { params }).then(data => commit('SET_LINKED_ITEMS', data))
    },
    createLogicLinkItem({commit}, payload = {}) {
      return API.post('LogicLinkItems', { data: payload }) 
    },
    updateLogicLinkItem({}, payload = {}) {
      return API.put('LogicLinkItems', { data: payload })
    },
    deleteLogicLinkItem({}, id = '') {
      return API.delete(`LogicLinkItems?id=${id}`, {});
    },

  },

  getters: {
  }
}