import moment from 'moment'

import auth0 from '../../services/auth0'

export default {
  namespaced: true,
  state: {
    error: null,
    accessToken: null,
    appState: null,
    expiresIn: null,
    idToken: null,
    idTokenPayload: null,
    refreshToken: null,
    scope: null,
    state: null,
    tokenType: null,

    loginTime: null
  },

  mutations: {
    LOGIN_FAILURE: (state, { error }) => {
      state.error = error
    },

    LOGIN_SUCCESS: (state, res) => {
      state.accessToken = res.accessToken
      state.appState = res.appState
      state.expiresIn = res.expiresIn
      state.idToken = res.idToken
      state.idTokenPayload = res.idTokenPayload
      state.refreshToken = res.refreshToken
      state.scope = res.scope
      state.state = res.state
      state.tokenType = res.tokenType

      state.loginTime = moment().valueOf()

      state.error = null
    },

    LOGOUT: (state) => {
      state.accessToken = null
      state.appState = null
      state.expiresIn = null
      state.idToken = null
      state.idTokenPayload = null
      state.refreshToken = null
      state.scope = null
      state.state = null
      state.tokenType = null

      state.loginTime = null
      state.error = null
    },
  },
  actions: {
    login ({ commit, state }) {
      auth0.authorize({
        redirectUri: `${window.location.origin}/auth0/callback`
      })
    },
    logout: ({ commit }) => {
      commit('LOGOUT')
      auth0.logout({
        returnTo: `${window.location.origin}/`
      })
    },
    parseHash: ({ commit }, hash) => {
      return new Promise((resolve, reject) => {
        auth0.parseHash({hash}, (err, res) => {
          window.location.hash = ''
          if (!err, !res) return reject(new Error('wtf'))
          if (err) {
            commit('LOGIN_FAILURE', {error: {
              error: err.error,
              message: err.errorDescription
            }})
            return reject(err)
          }
          commit('LOGIN_SUCCESS', res)
          return resolve()
        })
      })
    }
  },

  getters: {
    isAuthenticated: (state) => (typeof state.accessToken === 'string'),
    isTokenExpired (state) {
      if (state.idTokenPayload === null) return true;
      let exp = state.idTokenPayload.exp
      if (exp === null) return true;
      const expTime = moment(state.loginTime).add(exp, 'ms').valueOf()
      const nowTime = moment().valueOf()
      return nowTime > expTime
    }
  }
}