import Vue from 'vue'
import CoreuiVue from '@coreui/vue'

import App from './App'
import router from './router'
import store from './store'
import mixins from './mixins'
import { iconsSet as icons } from './assets/icons/icons.js'
import vSelect from 'vue-select'
// import CElementCover from './node-modules/@coreui/vue/src/components/element-cover/CElementCover';
import CoreuiVueCharts from '@coreui/coreui-vue-chartjs'


Vue.use(CoreuiVueCharts)
Vue.component('v-select', vSelect)
// Vue.component('c-element-cover', CElementCover)

Vue.config.performance = true
Vue.use(CoreuiVue)

Vue.mixin(mixins)

export default new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
})
