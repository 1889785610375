import API from '@/services/api';

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
    categoriesList: [],
    categoriesMap: {},
    linkedItemsList: []
  },

  mutations: {
    SET_LIST(state, fetchedData) {
      state.list = fetchedData.list,
      state.total = fetchedData.total
    },
    SET_CATEGORIES_LIST (state, fetchedData) {
      state.categoriesList = fetchedData.list
      fetchedData.list.forEach(category => state.categoriesMap[Number(category.id)] = category)
    },
    SET_LINKED_ITEMS (state, fetchedData) {
      state.linkedItemsList = fetchedData.list
    }
  },
  actions: {
    createMatch({}, payload = {}) {
      return API.post('ScoreLinks', { data: payload })
    },
    getList({commit}, params = {}) {
      return API.get('ScoreLinks', { params }).then(data => commit('SET_LIST', data))
    },
    updateMatch({}, payload = {}) {
      return API.put('ScoreLinks', { data: payload })
    },
    deleteMatch({}, id = '') {
      return API.delete(`ScoreLinks?id=${id}`, {});
    },
    getCategoriesList({commit}, params = {}) {
      return API.get('ScoreCategories', { params }).then(data => commit('SET_CATEGORIES_LIST', data))
    },
    getScoreLinksItemsList({commit}, params = {}) {
      return API.get('ScoreLinkItems', { params }).then(data => commit('SET_LINKED_ITEMS', data))
    },
    createScoreLinkItem({commit}, payload = {}) {
      return API.post('ScoreLinkItems', { data: payload }) 
    },
    deleteScoreLinkItem({}, id = '') {
      return API.delete(`ScoreLinkItems?id=${id}`, {});
    },

  },

  getters: {
  }
}