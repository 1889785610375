import API from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    total: 0,
  },

  mutations: {
    SET_GROUPS_LIST (state, {list, total}) {
      state.list = list
      state.total = total
    },
  },
  actions: {
    getGroupsList ({ commit }, params = {}) {
      return API.get('LogicLinkGroups', {params}).then(data => commit('SET_GROUPS_LIST', {
        list: data.list, total: data.total
      }))
    },
    createGroup ({ commit, dispatch, state }, body = {}) {
      return API.post('LogicLinkGroups', {data: body})
    },
    updateGroup ({ commit, dispatch, state }, body = {}) {
      return API.put('LogicLinkGroups', {data: body})
    },
    deleteGroup ({}, id = "") {
      return API.delete(`LogicLinkGroups?id=${id}`, {})
    }
  },

  getters: {
  }
}